html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

    nav ul {
        list-style: none;
    }

blockquote, q {
    quotes: none;
}

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

/* change colours to suit your needs */
ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

/* change colours to suit your needs */
mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

html, body {
    background: #fff;
}

@font-face {
    font-family: "Eames";
    src: url("../public/fonts/eames-regular.otf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../public/fonts/eames-medium.otf") format("opentype");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../public/fonts/eames-book.otf") format("opentype");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../public/fonts/eames-bold.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../public/fonts/eames-light-italic.otf") format("opentype");
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

.font-light {
    font-style: italic;
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-book,
.production-content p {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.body-text,
.production-content p {
    line-height: 1.25em;
    margin-bottom: 1.5em;
}

body {
    color: #000;
    font-family: "Eames";
    font-size: 18px;
}

a {
    color: initial;
    text-decoration: none;
}

.container {
    margin: 40px auto 20px;
    max-width: 1480px;
    padding: 0 20px;
}

.logoimage {
    display: block;
    height: auto;
    width: 100%;
}

.header-subtitle {
    margin: 18px 0 36px;
}

.slogan {
    margin: 0 0 40px;
    text-align: center;
}

.subtitle {
    font-size: 24px;
    margin: 0 0 40px;
    position: relative;
    text-align: center;
    text-transform: lowercase;
}

h2.subtitle:after {
    background: #000;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
}

.subtitle-text {
    background: #fff;
    color: #000 !important;
    display: inline-block;
    padding: 0 24px;
    position: relative;
    vertical-align: middle;
    z-index: 2;
}

button.subtitle-text {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    color: #000 !important;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: lowercase;
}

.subtitle-indicator {
    border: 2px solid #000;
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin: -4px 0 0 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;
}

.subtitle-indicator:after,
.subtitle-indicator:before {
    background: #000;
    border-radius: 2px;
    content: '';
    display: block;
    height: 2px;
    margin: 9px 0 0 4px;
    position: absolute;
    width: 12px;
}

.subtitle-indicator:after {
    transform: rotate(90deg);
}

.subtitle-indicator.subtitle-indicator-active:after {
    transform: rotate(0);
}

.section {
    margin-bottom: 48px;
}

.section-content {
    height: 0;
    margin-top: -40px;
    min-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.section-content.section-content-active {
    height: auto;
    margin-top: 0;
    min-height: 100px;
    opacity: 1;
    overflow: initial;
}

.people-item {
    margin: 0 0 20px;
}

.people-item-name {
    font-size: 21px;
    margin-bottom: 4px;
}

.people-item-title {
    font-size: 16px;
    margin-bottom: 4px;
}

.people-item-contact {
    font-size: 14px;
    margin-bottom: 4px;
}

.videolist-item {
    display: block;
    margin: 0 0 40px;
    transition: all .2s ease-in-out;
}

.videolist-item:hover,
.videolist-item:active,
.videolist-item:focus {
    opacity: .8;
}

.videolist-item-image {
    background: rgba(0,0,0,.3);
    display: block;
    height: auto;
    min-height: 140px;
    margin-bottom: 14px;
    transition: all .15s ease-in-out;
    width: 100%;
}

.videolist-item-image.videolist-item-image-production {
    margin-top: 10px;
}

.videolist-item-creator {
    font-size: 12px;
    margin-top: 2px;
    text-transform: uppercase;
}

.social-links {
    margin: 40px 0;
    text-align: center;
}

.social-link {
    display: inline-block;
    margin: 0 20px 20px;
    transition: all .2s ease-in-out;
}

.social-link:hover,
.social-link:active,
.social-link:focus {
    opacity: .8;
}

.footerlogo {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 80px;
}

.footer-text {
    margin: 8px 0 0;
    text-align: center;
}

.lightbox {
    background: rgba(0,0,0,.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}

.lightbox-outer {
    height: calc(100vh - 180px);
    margin: 90px 40px;
}

.lightbox-inner {
    height: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 1480px;
}

.lightbox-close {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 48px;
    height: 88px;
    line-height: 1em;
    padding: 20px;
    position: absolute;
    right: 0;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,.25);
    transition: all .2s ease-in-out;
    top: 0;
    width: 88px;
    z-index: 999;
}

.lightbox-close:hover,
.lightbox-close:focus,
.lightbox-close:active {
    opacity: .7;
}

@media (min-width: 600px) {
    .people {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .people-item {
        flex: 1;
        margin: 0 15px 20px;
        max-width: calc(50% - 30px);
        min-width: calc(50% - 30px);
    }

    .videolist {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
    }

    .videolist-item {
        flex: 1;
        margin: 0 20px 40px;
        max-width: calc(50% - 40px);
        min-width: calc(50% - 40px);
    }

    .videolist-item.videolist-item-latest.videolist-item-first {
        max-width: calc(100% - 40px);
        min-width: calc(100% - 40px);
    }

    .videolist-item.videolist-item-latest {
        max-width: calc(33.33% - 40px);
        min-width: calc(33.33% - 40px);
    }

    .videolist-item-title.videolist-item-title-first {
        font-size: 24px;
    }

    .videolist-item-creator.videolist-item-creator-first {
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .people-item {
        max-width: calc(33.33% - 30px);
        min-width: calc(33.33% - 30px);
    }
}

@media (min-width: 1040px) {
    .videolist-item {
        max-width: calc(25% - 40px);
        min-width: calc(25% - 40px);
    }

    .videolist-item.videolist-item-production {
        max-width: calc(50% - 40px);
        min-width: calc(50% - 40px);
    }
}

@media (min-width: 1200px) {
    .container {
        margin: 100px auto 20px;
        max-width: 1480px;
        padding-bottom: 80px;
    }

    .header-subtitle {
        font-size: 24px;
        margin: 24px 0 48px;
    }

    .subtitle {
        font-size: 40px;
    }

    .subtitle-indicator {
        height: 30px;
        margin: -6px 0 0 24px;
        width: 30px;
    }

    .subtitle-indicator:after,
    .subtitle-indicator:before {
        height: 2px;
        margin: 14px 0 0 7px;
        width: 16px;
    }

    .directors-container {
        text-align: center;
    }

    .people {
        display: block;
        flex-wrap: initial;
        text-align: center;
    }

    .people.people-directors {
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .people-item {
        display: inline-block;
        max-width: none;
        min-width: 0;
        text-align: left;
        vertical-align: top;
    }

    .people-item.director-item {
        margin-bottom: 30px;
    }

    .videolist-item.videolist-item-latest {
        max-width: calc(33.33% - 40px);
        min-width: calc(33.33% - 40px);
    }

    .videolist-item.videolist-item-latest.videolist-item-first {
        max-width: calc(100% - 40px);
        min-width: calc(100% - 40px);
    }

    .lightbox-outer {
        height: calc(100vh - 180px);
        margin: 90px 80px;
    }
}

@media (min-width: 1280px) {
    .container {
        padding: 0 80px 80px;
    }

    .people {
        margin: 0 -20px;
    }

    .people-item {
        margin: 0 20px 20px;
    }

    .lightbox-outer {
        height: calc(100vh - 320px);
        margin: 160px 80px;
    }

    .lightbox-close {
        margin: 40px;
    }
}

@media (min-width: 1400px) {
    .people-item-name {
        font-size: 24px;
    }
    
    .people-item-title {
        font-size: 18px;
    }

    .lightbox-outer {
        height: calc(100vh - 320px);
        margin: 160px;
    }
}

/* ----------------------- */

.lds-ring {
    display: block;
    margin: 80px auto;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring-absolute {
    left: 50%;
    margin: -40px 0 0 -40px;
    position: absolute;
    top: 50%;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  .lds-ring.lds-ring-white div {
    border: 8px solid #fff;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  